import { render, staticRenderFns } from "./shareRule.vue?vue&type=template&id=757a7289&scoped=true&"
var script = {}
import style0 from "./shareRule.vue?vue&type=style&index=0&id=757a7289&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "757a7289",
  null
  
)

export default component.exports